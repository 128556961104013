<template>
    <div class="fill-height">
        <v-container v-if="mdAndDown" class="fill-height text-center">
            <div
                class="d-flex flex-column justify-space-around fill-height fill-width"
            >
                <div class="d-flex flex-column fill-width">
                    <div class="max-w-100 mx-auto" style="height: 190.91px">
                        <div
                            v-show="isLogoAnimationCompleted"
                            style="
                                width: 344px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <img
                                style="max-width: 268px"
                                src="@/assets/images/logo/noodzly-logo.png"
                            />
                        </div>
                        <img
                            v-show="!isLogoAnimationCompleted"
                            style="width: 344px"
                            src="@/assets/logo_animation.gif"
                        />
                    </div>
                    <span class="splash-text mt-6">{{ welcomeMessage }}</span>
                </div>
                <div class="fill-width mt-8">
                    <btn-large
                        class="btn-accent"
                        to="/sign-up"
                        text="Sign Up"
                    />
                    <btn-large
                        class="grey darken-3 mt-3"
                        to="/login"
                        text="Login"
                    />
                </div>
                <div class="text-center mt-4">
                    <static-page-links template="sleeve" />
                    <div class="text-center mt-6">
                        <social-links />
                    </div>
                </div>
                <footer-contact />
                <footer-logo />
            </div>
        </v-container>

        <div v-else class="fill-height d-flex flex-column">
            <div class="mt-10 max-w-100 mx-auto" style="height: 190.91px">
                <div
                    v-show="isLogoAnimationCompleted"
                    style="
                        width: 344px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    "
                >
                    <img
                        style="max-width: 268px"
                        src="@/assets/images/logo/noodzly-logo.png"
                    />
                </div>
                <img
                    v-show="!isLogoAnimationCompleted"
                    style="width: 344px"
                    src="@/assets/logo_animation.gif"
                />
            </div>
            <v-container class="container-splash px-10">
                <v-row justify="space-between" align="center">
                    <v-col cols="5">
                        <div
                            ref="parallaxScene"
                            class="animate__animated animate__fadeInUp"
                        >
                            <div
                                data-depth="0.2"
                                class="parallax-scene__screen home-screen"
                            >
                                <v-card
                                    rounded="xl"
                                    class="mt-12"
                                    :max-width="screenWidth"
                                >
                                    <v-img
                                        :width="screenWidth"
                                        src="@/assets/images/splash/app-home-screen-2.jpg"
                                    />
                                </v-card>
                            </div>

                            <div
                                data-depth="0.6"
                                class="parallax-scene__screen splash-screen"
                            >
                                <v-card
                                    rounded="xl"
                                    class="mt-n12"
                                    :max-width="screenWidth"
                                >
                                    <v-img
                                        :width="screenWidth"
                                        class="align-end"
                                        src="@/assets/images/splash/app-splash-screen.jpg"
                                    >
                                        <div class="pa-6">
                                            <div class="pb-7">
                                                <v-btn
                                                    class="btn-accent"
                                                    to="/sign-up"
                                                    block
                                                >
                                                    Sign Up
                                                </v-btn>
                                                <v-btn
                                                    color="grey darken-3"
                                                    class="mt-3"
                                                    to="/login"
                                                    block
                                                >
                                                    Login
                                                </v-btn>
                                            </div>
                                            <div class="py-10 text-center">
                                                <static-page-links
                                                    template="spigot"
                                                />
                                            </div>
                                        </div>
                                    </v-img>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <div
                            class="animate__animated animate__fadeIn animate__slow"
                        >
                            <div class="splash-text">
                                {{ $t("pages.home.desktop_description") }}
                            </div>
                            <div class="qrcode">
                                <div class="qrcode-text">
                                    {{ $t("pages.home.qr_code_sub_text") }}
                                </div>
                                <img
                                    :src="
                                        require('@/assets/images/home/noodzly-qrcode.jpg')
                                    "
                                    alt="qrcode"
                                    title="qrcode"
                                    class="qrcode-img"
                                />
                                <div class="qrcode-text">
                                    {{ $t("pages.home.qr_code_text") }}
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <div
                class="mt-auto animate__animated animate__fadeInUp animate__fast"
            >
                <v-divider />
                <v-container class="px-10">
                    <static-page-links
                        template="simple"
                        style="display: flex; justify-content: center"
                    />
                    <footer-contact />
                    <social-links class="mt-8" />
                    <footer-logo />
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
import breaks from "@/mixins/breaks";
import Parallax from "parallax-js";
import SocialLinks from "@/components/app/auth/SocialLinks";
import { MESSAGE_ON_WELCOME_PAGE } from "@/configs/constants";
import StaticPageLinks from "@/components/app/common/StaticPageLinks";
import FooterContact from "@/components/app/common/FooterContact";
import FooterLogo from "@/components/app/common/FooterLogo";

export default {
    name: "SplashScreen",
    mixins: [breaks],
    data: () => ({
        parallaxSceneEl: null,
        parallaxInstance: null,
        welcomeMessage: MESSAGE_ON_WELCOME_PAGE,
        isLogoAnimationCompleted: false,
    }),
    mounted() {
        if (this.$auth.user()) {
            this.$router
                .push({ name: "auth.home" })
                .catch((e) => console.log(e));
        }
        if (this.lgAndUp) {
            this.parallaxSceneInit();
        }

        setTimeout(() => {
            this.isLogoAnimationCompleted = true;
        }, 3000);
    },
    computed: {
        screenWidth() {
            if (this.xlOnly) {
                return 289;
            }
            if (this.lgAndUp) {
                return 230;
            }

            return 150;
        },
    },
    beforeDestroy() {
        if (this.parallaxInstance) this.parallaxInstance.destroy();
    },
    methods: {
        parallaxSceneInit() {
            this.parallaxSceneEl = this.$refs.parallaxScene;
            this.parallaxInstance = new Parallax(this.parallaxSceneEl, {
                pointerEvents: true,
            });
        },
    },
    components: {
        FooterLogo,
        FooterContact,
        SocialLinks,
        StaticPageLinks,
    },
};
</script>

<style lang="scss">
@import "../../sass/vuetify/variables";

.qrcode {
    margin-top: 55px;
}
.qrcode-text {
    font-size: 1.8rem;
}
.qrcode-img {
    max-width: 180px;
    margin: 15px 0;
}
.splash-text {
    font-size: 4.6vw;
}

.parallax-scene {
    &__screen {
        &.splash-screen {
            margin-left: 280px;
        }

        &.home-screen {
            margin-left: 100px;
        }

        .v-card {
            box-shadow: -40px 30px 70px rgba(#000, 0.36) !important;
        }
    }
}

@include media("md-and-up") {
    .splash-text {
        font-size: 1.8rem;
    }
}

@include media("xl-only") {
    .splash-text {
        font-size: 2rem;
    }
    .parallax-scene {
        &__screen {
            &.splash-screen {
                margin-left: 230px;
            }

            &.home-screen {
                margin-left: 0;
            }

            .v-card {
                box-shadow: -60px 50px 96px rgba(#000, 0.36) !important;
            }
        }
    }
}
</style>
